<script lang='ts'>
  import Icon from '@/components/icons/Icon.svelte'
  import FakeButton from '@/components/ui/FakeButton.svelte'

  export let liked = false
  export let onClick: (e: MouseEvent | KeyboardEvent) => void

  const handleClick = (e: MouseEvent | KeyboardEvent) => {
    onClick(e)
    liked = !liked
  }
</script>
<FakeButton onClick={handleClick} onKeyPress={handleClick}>
  {#if !liked}
    <Icon icon='Heart' weight='regular' />
  {:else}
    <span class='heart'><Icon icon='Heart' /></span>
  {/if}
</FakeButton>

<style lang='scss'>
  :global(.fake-button .heart) {
    width: 2.4rem;
    height: 2.4rem;
    color: var(--Accent-Medium);
  }
</style>
